.category {
    background-image: url(https://images.unsplash.com/photo-1667262422332-9b09d4065117?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80);
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100%;
    cursor: pointer;
}

.category:hover::before {
    background-color: rgba(0,0,0,0.3);
}

.category::before{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
}

.category p {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    color: white;
}

.category img {
    width: 100%;
}